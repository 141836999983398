var header = document.querySelector(".header");

window.addEventListener("scroll", () => {
  if (window.scrollY > 50) {
    header.classList.add("header--sticky");
  } else {
    header.classList.remove("header--sticky");
  }
});

// ======= mobile menu toggle ========
const mobileMenu = document.getElementById("mobile-menu");
const navbarToggle = document.getElementById("navbar-toggle");
const mobileHeader = document.getElementById("mobile-header");
const body = document.querySelector("body");

navbarToggle.onclick = function () {
  mobileMenu.classList.toggle("is-active");
  navbarToggle.classList.toggle("is-active");
  mobileHeader.classList.toggle("is-active");
  body.classList.toggle("disable-scroll");
};

// ================= faq (accordion) section ===============
const accordionBtns = document.querySelectorAll(".accordion");

accordionBtns.forEach((accordion) => {
  accordion.onclick = function () {
    this.classList.toggle("is-open");

    let content = this.nextElementSibling;

    if (content.style.maxHeight) {
      //this is if the accordion is open
      content.style.maxHeight = null;
    } else {
      //if the accordion is currently closed
      content.style.maxHeight = content.scrollHeight + "px";
    }
  };
});

// ============ javascript counter =============
// function visible(partial) {
//   var $t = partial,
//     $w = jQuery(window),
//     viewTop = $w.scrollTop(),
//     viewBottom = viewTop + $w.height(),
//     _top = $t.offset().top,
//     _bottom = _top + $t.height(),
//     compareTop = partial === true ? _bottom : _top,
//     compareBottom = partial === true ? _top : _bottom;

//   return (
//     compareBottom <= viewBottom && compareTop >= viewTop && $t.is(":visible")
//   );
// }

// $(window).scroll(function () {
//   if (visible($(".count-digit"))) {
//     if ($(".count-digit").hasClass("counter-loaded")) return;
//     $(".count-digit").addClass("counter-loaded");

//     $(".count-digit").each(function () {
//       if ($(this).html() == Math.floor($(this).html())) {
//         var $this = $(this);
//         jQuery({ Counter: 0 }).animate(
//           { Counter: $this.text() },
//           {
//             duration: 1500,
//             easing: "swing",
//             step: function () {
//               $this.text(Math.trunc(this.Counter) + 1);
//             },
//           }
//         );
//       } else {
//         var $this = $(this);
//         jQuery({ Counter: 0 }).animate(
//           { Counter: $this.text() },
//           {
//             duration: 1500,
//             easing: "swing",
//             step: function () {
//               $this.text(((this.Counter * 10) / 10).toFixed(1));
//             },
//           }
//         );
//       }
//     });
//   }
// });

$(function(){
  $('.nav ul a.nav__link').each(function() {
    if ($(this).prop('href') == window.location.href) {
      $(this).addClass('active');
    }
  });
});

// ========= pop up toggle  ==============
$(function () {
  $(".request-quote-button").click(function () {
    $("#request-quote-pop-up").fadeToggle();
    $(body).toggleClass("active");
  });

  $(".close-pop-up").click(function () {
    $(".pop-up-form").fadeToggle();
    $(body).toggleClass("active");
  });
});

